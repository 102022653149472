import axios from 'axios';

export default class Mxpi {
  constructor() {
    this.axios = axios.create({
      baseURL: 'https://api.mxstore.com.au/api',
      headers: {
        Authorization:
          'Bearer lr890tzhvBQsDjQvDQTKsckoLeqlWQaT3kpIm8WB532rpH4Nnh3hgROjNEjv',
      },
    });
  }

  async bikes(parentId = null) {
    const response = await this.axios.get('bikes/selector', {
      params: { parentId },
    });

    return response.data;
  }
}
