import Cookies from 'js-cookie';

// test for localStorage support
let modern = true;
try {
    const t = '@@';
    localStorage.setItem(t, '1');
    localStorage.removeItem(t);
} catch (e) {
    modern = false;
}

export default class Storage {
    constructor(options = {}) {
        this.options = {
            session: false,
            ...options,
        };

        this.defaultExpiry = this.options.session ? 0.5 : 30;
    }

    getItem(key) {
        if (! modern) return Cookies.get(key);

        const response = this.options.session ? sessionStorage.getItem(key) : localStorage.getItem(key);

        try {
            const json = JSON.parse(response);
            const { _storageDate: date, _storageExpires: expires, data } = json;
            if (! date && ! expires) return response;

            const now = Math.round(new Date().getTime() / 1000);
            return (now - date < expires ? data : null);
        } catch (e) {
            // i guess it's just a normal entry
            return response;
        }
    }

    setItem(key, value, expires = null) {
        if (! modern) {
            return Cookies.set(key, value, {
                expires: expires || this.defaultExpiry,
                secure: true,
            });
        }

        const storage = this.options.session ? sessionStorage : localStorage;

        if (expires) {
            return storage.setItem(key, JSON.stringify({
                _storageDate: Math.round(new Date().getTime() / 1000),
                _storageExpires: expires * 86400,
                data: value,
            }));
        }

        return storage.setItem(key, value);
    }

    removeItem(key) {
        if (! modern) return Cookies.remove(key);
        return this.options.session ? sessionStorage.removeItem(key) : localStorage.removeItem(key);
    }
}
