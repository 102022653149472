<template>
  <div class="parts-finder-modal">
    <div class="p-2 text-white text-2xl font-bold bg-blue uppercase">
      Parts Finder
    </div>
    <div class="p-2 border border-t-0 border-solid border-blue">
      <bike-selector v-slot="selector" :stacked="true">
        <button
          type="button"
          class="button button-sm button-black rounded uppercase"
          :disabled="!selector.selected"
          @click="select(selector.selected)"
        >
          <template v-if="shop">Shop Parts &amp; Accessories</template>
          <template v-else>Select Bike</template>
        </button>
      </bike-selector>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    shop: true,
  }),
  mounted() {
    this.field = $('#request_custom_fields_81314128');
    this.shop = !this.field.length;
  },
  methods: {
    select(bike) {
      if (this.shop) {
        window.location = `https://www.mxstore.com.au${bike.url}?utm_source=help&utm_medium=bikeselector&utm_campaign=quicklinks`;
      } else {
        this.field.val(bike.name);
        $.fancybox.close();
        allowSubmit();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.parts-finder-modal {
  max-width: 100%;
  width: 320px;
}
</style>
