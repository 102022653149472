import Vue from 'vue';
import Mxpi from '~/util/mxpi';

export default class Hc {
  constructor() {
    this.bus = new Vue();

    // instantiate search utility
    this.mxpi = new Mxpi();
  }

  boot() {
    // Instantiate the Vue App
    this.app = new Vue({
      el: '#app',
    });
  }

  /**
   * Registers some event listeners for presenting toast. Creates a
   * generic and configurable toast listener, then some more
   * specific toast types for convenience
   */

  /**
   * Register a listener on Ex's built-in event bus
   */
  $on(...args) {
    this.bus.$on(...args);
  }

  /**
   * Register a one-time listener on the event bus
   */
  $once(...args) {
    this.bus.$once(...args);
  }

  /**
   * Unregister a listener on the event bus
   */
  $off(...args) {
    this.bus.$off(...args);
  }

  /**
   * Emit an event on the event bus
   */
  $emit(...args) {
    this.bus.$emit(...args);
  }
}
