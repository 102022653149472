<template>
  <div class="flex" :class="stacked ? 'flex-col w-full' : 'items-center'">
    <div
      v-for="(selector, index) in selectors"
      :key="index"
      :class="stacked ? 'mb-2' : 'pr-2 flex-1'"
    >
      <select
        v-model="selector.selected"
        class="bike-selector w-full border border-gray-300"
        :disabled="!selector.options || selector.options.length < 1"
        @change="getOptions(index, selector)"
      >
        <option value="">
          {{ selector.display || 'Please select' }}
        </option>
        <option
          v-for="option in selector.options"
          :key="option.id"
          :value="option"
        >
          {{ option.name }}
        </option>
      </select>
    </div>

    <!-- button slot! -->
    <slot :selected="selected" :reset="reset" />
  </div>
</template>

<script>
export default {
  props: {
    stacked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectors: [
        {
          display: 'Select Make',
          selected: '',
          options: [],
        },
        {
          display: 'Select Model',
          selected: '',
          options: [],
        },
        {
          display: 'Select Year',
          selected: '',
          options: [],
        },
      ],
      selected: null,
    };
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    const bikes = await Hc.mxpi.bikes();
    this.selectors[0].options = bikes;
  },
  methods: {
    async getOptions(index, selector) {
      // If the selection is an empty string, the 'Please Select' value was selected so
      // we clear all selectors after this one.
      if (selector.selected === '') {
        this.reset(index);
        return;
      }

      if (selector.selected.type === 'year') {
        this.selected = selector.selected;
        this.$emit('selected', this.selected);
        return;
      }

      this.reset(index);
      // eslint-disable-next-line no-undef
      const bikes = await Hc.mxpi.bikes(selector.selected.id);
      this.selectors[index + 1].options = bikes;
    },
    reset(fromIndex = -1) {
      this.selected = null;
      this.selectors.forEach((select, i) => {
        if (i > fromIndex) {
          Vue.set(select, 'selected', '');

          if (i > 0) {
            Vue.set(select, 'options', []);
          }
        }
      });
    },
  },
};
</script>
